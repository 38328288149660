import React, {Component} from 'react';
import {Container, Form, Grid, Image, Radio, Table} from "semantic-ui-react"

import appStore from "store/appStore"
import "./PDFPage.css"

export default class PDFPage extends React.Component {

	state = {
		doc: false
	}

	componentDidMount() {
		this.load()
	}


	async load( props = this.props ) {
		const doc = await appStore.fetch( `/api/document/${props.params.documentId}` )
		console.log( {doc} )
		this.setState( {doc} )
	}


	render() {
		const {doc} = this.state
		if ( !doc ) return null
		const {form, signatures} = doc
		return <div>
			<ContractDocument form={form} signatures={signatures} />
		</div>;
	}
}

class ContractDocument extends Component {


	render() {
		const {form = {}, signatures = []} = this.props
		return (
			<div className={"PDFPage pt5-ns pt4"}>
				<Form>
					<Container>
						<h3>Konditionsvereinbarungen 2024 für Kränzle Fachhandelspartner</h3>
						<Form.Group>
							<Form.Field>
								<Radio
									label="Bestandskunde"
									name="customerType"
									value={"Bestandskunde"}
									checked={form.customerType === "Bestandskunde"}
								/>
							</Form.Field>
							<Form.Field>
								<Radio
									label="Neukunde"
									name="customerType"
									value={"Neukunde"}
									checked={form.customerType === "Neukunde"}
								/>
							</Form.Field>
						</Form.Group>

						<div className="pb3">
							<Grid columns={2}>
								<Grid.Row>
									<Grid.Column>
										<Table compact basic="very">
											<Table.Body>
												<Table.Row>
													<Table.Cell>Name</Table.Cell>
													<Table.Cell><DataField value={form["Name"]} fluid name={"Name"} /></Table.Cell>
												</Table.Row>

												<Table.Row>
													<Table.Cell>Straße</Table.Cell>
													<Table.Cell><DataField value={form["Straße"]} fluid name={"Straße"} /></Table.Cell>
												</Table.Row>
												<Table.Row>
													<Table.Cell>PLZ</Table.Cell>
													<Table.Cell><DataField value={form["PLZ"]} name={"PLZ"} /> </Table.Cell>
												</Table.Row>
												<Table.Row>
													<Table.Cell>Ort</Table.Cell>
													<Table.Cell><DataField value={form["Ort"]} name={"Ort"} /></Table.Cell>
												</Table.Row>
											</Table.Body>
										</Table>

									</Grid.Column>

									<Grid.Column>
										<Table compact basic="very">
											<Table.Body>
												<Table.Row>
													<Table.Cell>KundenNr.</Table.Cell>
													<Table.Cell><DataField fluid value={form["KdnId"]} readOnly name={"KdnId"} /></Table.Cell>
												</Table.Row>

												<Table.Row>
													<Table.Cell>Rabattsatz</Table.Cell>
													<Table.Cell><DataField fluid value={form["Rabattsatz"]} readOnly name={"Rabattsatz"} /></Table.Cell>
												</Table.Row>
												<Table.Row>
													<Table.Cell>Verband</Table.Cell>
													<Table.Cell><DataField fluid value={form["Verband"]} name={"Verband"} /></Table.Cell>
												</Table.Row>
												<Table.Row>
													<Table.Cell>MitgliedsNr. Verband</Table.Cell>
													<Table.Cell><DataField fluid value={form["MitgliedsNr_Verband"]} name={"MitgliedsNr_Verband"} /></Table.Cell>
												</Table.Row>

											</Table.Body>
										</Table>
									</Grid.Column>
								</Grid.Row>
							</Grid>

							<div className="pt3">
								<h3>KONDITIONSKRITERIEN für Teilnehmer des selektiven Vertriebssystems von KRÄNZLE</h3>


								<Table>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>Kriterien</Table.HeaderCell>
											<Table.HeaderCell>Rabattstufe</Table.HeaderCell>
											<Table.HeaderCell>Auswahl</Table.HeaderCell>
										</Table.Row>
									</Table.Header>

									<Table.Body>
										{/*RABATTSTUFE 1*/}
										<Table.Row>
											<Table.Cell>
												<h4>Fachhändler</h4>
												<ul>
													<li> Stationärer Verkaufspunkt mit oder ohne Internetauftritt / Onlineshop*.</li>
													<li>Ausstellung von min. 3 verschiedenen Geräten aus dem aktuellen Programm von KRÄNZLE</li>
													<li>
														Verwendung des KRÄNZLE Shopsystems oder eines vergleichbaren, hochwertigen eigenen
														Shopsystems inkl. Anbringung eines Kränzle-Schriftzugs (Die Verwendung eines eigenen
														Shopsystems bedarf der Freigabe durch Kränzle)
													</li>
													<li> Fachhändler stellt Ersatzteile, Zubehör, Verbrauchsmaterial oder Ähnliches bei Kundenanfragen
														 zur Verfügung
													</li>
												</ul>
											</Table.Cell>
											<Table.Cell> 34%</Table.Cell>
											<Table.Cell>
												<Form.Field>
													<Radio
														name="Rabattsatz"
														value="34%"
														checked={form["Rabattsatz"] === "34%"}

													/>
												</Form.Field>
											</Table.Cell>
										</Table.Row>

										{/*RABATTSTUFE 2*/}
										<Table.Row>
											<Table.Cell>
												<h4> Fachhändler mit einfachem Service</h4>
												<ul>
													<li> alle Kriterien von Fachhändler</li>
													<li>Ausstellung von min. 5 verschiedenen Geräten aus dem aktuellen Programm von KRÄNZLE</li>
													<li>
														Lagerware (zusätzlich zur Ausstellungsware).
													</li>
													<li> Einfacher Service in einer Reparaturwerkstatt</li>
													<li>Teilnahme an Kränzle-Schulungen</li>
												</ul>
											</Table.Cell>
											<Table.Cell> 36%</Table.Cell>
											<Table.Cell>
												<Form.Field>
													<Radio
														name="Rabattsatz"
														value="36%"
														checked={form["Rabattsatz"] === "36%"}

													/>
												</Form.Field>
											</Table.Cell>
										</Table.Row>

										{/*RABATTSTUFE 3*/}
										<Table.Row>
											<Table.Cell>
												<h4>Fachhändler mit Full-Service</h4>
												<ul>
													<li>alle Kriterien von Fachhändler mit einfachem Service</li>
													<li>Vollwertiger Service in einer Reparaturwerkstatt</li>
													<li>KRÄNZLE Spezialwerkzeug</li>
													<li>Ersatzteilbevorratung</li>
													<li>Qualifiziertes Personal zur Durchführung von Reparaturen an elektrischen Bauteilen</li>
													<li>Teilnahmepflicht an Kränzle-Schulungen (Nachweis des Besuchs einer KRÄNZLE Serviceschulung in den letzten 36 Monaten vor Abschluss dieser Vereinbarung
														erforderlich).
													</li>
												</ul>
											</Table.Cell>
											<Table.Cell> 38%</Table.Cell>
											<Table.Cell>
												<Form.Field>
													<Radio
														name="Rabattsatz"
														value="38%"
														checked={form["Rabattsatz"] === "38%"}

													/>
												</Form.Field>
											</Table.Cell>
										</Table.Row>

									</Table.Body>
								</Table>

								<div className="f6">
									* Internetauftritt / Onlineshop: Vertragsprodukte dürfen nur mit entsprechenden aktuellen Produktabbildungen und Logos von Kränzle präsentiert werden.
									Selbsterstellte Produktabbildungen und
									Darstellungen bedürfen der vorherigen Zustimmung durch Kränzle (detaillierte Inhalte ergeben sich aus dem Kränzle Fachhandelspartnervertrag sowie Lizenzvertrag!).
								</div>

							</div>
						</div>
					</Container>

					<Container style={{pageBreakBefore: "always"}}>
						{/*<Image centered src={"/img/form-a.png"}/>*/}
						{/*<Image centered src={"/img/form-b.png"}/>*/}
						<h2 className={"pt3"}>Konditionsvereinbarung 2025 für Kränzle Fachhandelspartner</h2>
						<h4 className={"ttu ma0 pb1"}>BONUSVEREINBARUNG</h4>

						<div className="f6 flex flex-row justify-between">
							<div>
								<div className=""><span className="b"> Bonuskomponente 1:</span> Staffelbonus auf den Gesamtnettoumsatz</div>
								<div>20.000 € Nettoumsatz: 1,00 %</div>
								<div>30.000 € Nettoumsatz: 1,25 %</div>
								<div>40.000 € Nettoumsatz: 1,50 %</div>
								<div>50.000 € Nettoumsatz: 1,75%</div>
								<div>≥ 60.000 € Nettoumsatz: 2,00 %</div>
							</div>

							<div>
								<div className=""><span className="b">  Bonuskomponente 2: </span> Vorjahresbonus</div>
								<div>bei Erreichung des Vorjahresumsatzes: 1,00 %</div>
								<div>&nbsp;</div>
								<div>(Voraussetzung min. 20.000 € Nettoumsatz im Abrechnungsjahr)</div>
							</div>
						</div>
						<div className="f7 pt2 pb2">
							<p>Boni werden auf den gesamten Nettoumsatz innerhalb des Abrechnungsjahres mit KRÄNZLE Produkten gewährt.
							   Ausgenommen ist Umsatz mit KRÄNZLE POS-Artikeln, Promotion-Artikeln, Werbemitteln und Servicerechnungen.</p>
							<p>Bei Abrechnung über einen Verband gelten ausschließlich die mit dem jeweiligen Verband vereinbarten umsatzbezogenen
							   Bonusvereinbarungen. Die Bonuskomponenten 1 und 2 finden nur Anwendung, wenn keine Bonusvereinbarung mit dem Verband
							   existiert.</p>
							<p>Der Betrag wird im Folgejahr (i.d.R. bis Ende Februar) gutgeschrieben. Beginnt eine Zusammenarbeit im laufenden
							   Geschäftsjahr, so wird ein möglicher Bonus im Folgejahr anteilig vergütet.</p>
						</div>

						<div className="f6">
							<h4 className={"ttu ma0 pv1"}>ZAHLUNG</h4>
							<div className="b">10 Tage 3% Skonto, 30 Tage netto</div>
							<div>Bei Abrechnung über einen Verband gelten ausschließlich die mit dem jeweiligen Verband vereinbarten Zahlungsbedingungen.</div>
						</div>

						<h4 className={"ttu ma0 pv1"}>LIEFERBEDINGUNGEN</h4>
						<div className="flex flex-row justify-between f6">
							<p>
								Für die Versendung berechnen wir die Kosten, die sich aus der zum Zeitpunkt des Gefahrübergangs maßgeblichen Versandkostenregelung ergeben.
								<br/>
								Die jeweils gültigen Versandkosten, Frachtfreigrenzen und Mindermengenzuschläge können im Kränzle Händler-Login (unter haendler.kraenzle.com) eingesehen und heruntergeladen werden.
							</p>
							{/*<div>*/}
							{/*	<div className="b ">Speditionsversand:</div>*/}
							{/*	<div className={"pb1"}> Bei einem Nettowarenwert unter 650,00 € werden 45,00 €*/}
							{/*		Frachtkostenpauschale erhoben.*/}
							{/*	</div>*/}
							{/*	<div className="b ">Paketversand:</div>*/}
							{/*	<div className={"pb1"}>Bei einem Nettowarenwert unter 250,00 € werden 6,90 €*/}
							{/*	   Frachtkostenpauschale erhoben.</div>*/}
							{/*</div>*/}
							{/*<div>*/}
							{/*	<div className="b ">Mindermengenzuschlag:</div>*/}
							{/*	<div className={"pb1"}>Bei einer Lieferung unter 30,00 € Nettowarenwert werden*/}
							{/*	   5,00 € Mindermengenzuschlag erhoben.</div>*/}
							{/*</div>*/}
						</div>

						<hr />

						<div className={"f7"}>
							<div className={"pb1"}>Die Vereinbarung hat eine Laufzeit vom 01.01.2025 bis 31.12.2025. Die rückwirkende Gültigkeit ist ausgeschlossen. Alle vorherigen Vereinbarungen zu Konditionen
							   zwischen KRÄNZLE und dem Fachhandelspartner verlieren mit Inkrafttreten dieser Konditionsvereinbarung - ungeachtet evtl. ausgesprochener Kündigungen - ihre
							   Gültigkeit. Es gelten ausschließlich die im Geltungszeitraum dieser Konditionsvereinbarung jeweils aktuellen Allgemeinen Bedingungen für Lieferungen und Leistungen
							   von KRÄNZLE (AGB) als vereinbart, die unter www.kraenzle.com/agb eingesehen werden können. Gerichtsstand ist Illertissen.</div>
							<div className={"pb1"}>Alle Warenlieferungen erfolgen bis zum Eingang aller Zahlungen aus dem bestehenden Kontokorrentverhältnis (Geschäftsverbindung) unter Eigentumsvorbehalt. Der
							   Abschluss dieser Konditionsvereinbarung berechtigt den Fachhandelspartner für die Dauer dieser Vereinbarung, Vertragsprodukte jeweils aus dem aktuellen
							   Programm zu Listenpreisen zzgl. der jeweils gültigen gesetzlichen Mehrwertsteuer zu beziehen.</div>
							<div className={"pb1"}>Regelmäßig bis zum Ende eines Kalenderjahres wird KRÄNZLE dem Fachhandelspartner für die in der Konditionsvereinbarung des Folgezeitraums festgelegte
							   Geltungsdauer Bezugskonditionen auf Vertragsprodukte mit Listenpreisen anbieten, die auf der zuvor von KRÄNZLE durchgeführten Beurteilung der erfüllten
							   Leistungskriterien basieren.</div>
							<div className={"pb1"}>Die Gewährung dieser Bezugskonditionen setzt den Bestand eines Fachhandelspartnervertrages sowie die Erfüllung der vertraglich vereinbarten Leistungsverpflichtungen des Fachhandelspartners während der gesamten Vertragslaufzeit voraus. Bei Verstößen gegen die Verpflichtungen aus dieser Konditionsvereinbarung
							   bzw. dem Fachhandelspartnervertrag werden die Konditionen des Fachhandelspartners gemäß den Regelungen der Konditionsvereinbarung bzw. des
							   Fachhandelspartnervertrages bei Vorliegen der entsprechenden Voraussetzungen angepasst bzw. kann der Fachhandelspartnervertrag gekündigt werden. Letzteres
							   führt auch zur Beendigung der Konditionsvereinbarung.</div>
							<div className={"pb1"}>Die Bezugskonditionen sind stets nur bezogen auf den in der Konditionsvereinbarung abschließend benannten Fachhandelspartner und befristet auf den in der
							   Konditionsvereinbarung genannten Geltungszeitraum. Ein Anspruch auf die Einräumung dieser Bezugskonditionen auf Dritte oder für Folgezeiträume wird zwischen
							   KRÄNZLE und dem Fachhandelspartner ausdrücklich ausgeschlossen.</div>
							<div className={"pb1"}>Die Parteien sind sich einig, dass die Konditionsvereinbarung auch durch eine elektronische Unterschrift beider Vertragsparteien Gültigkeit erhält.
							   Der Fachhandelspartner bestätigt, alles Vorstehende gelesen und verstanden zu haben, und versichert, dass seine vorstehenden Angaben den Tatsachen
							   entsprechen.</div>
						</div>

						<div className="">
							<div className="pt3 f6">
								<Grid columns={2}>
									<Grid.Row>
										<Grid.Column>
											<Table basic="very" compact={"very"}>
												<Table.Body>
													<Table.Row>
														<Table.Cell width={6}><DataField label={"Ort "} fluid value={form["Signatur_Partner_Ort"]}
																						 name={"Signatur_Partner_Ort"} /></Table.Cell>
														<Table.Cell width={6}><DataField label={"Datum"} fluid value={form["Signatur_Partner_Zeit"]}
																						 name={"Signatur_Partner_Zeit"} /></Table.Cell>
													</Table.Row>

													<Table.Row>
														<Table.Cell colSpan={2}><DataField label={"Name"} fluid value={form["Signatur_Partner_Name"]}
																						   name={"Signatur_Partner_Name"} /></Table.Cell>

													</Table.Row>

													<Table.Row>
														<Table.Cell colSpan={2}>
															<div className={"f6"}>Unterschrift Fachhandelspartner</div>
															<Image className={"mw-100"} src={signatures[0]} />
															{/*<Signature store={form} className={"bg-white ba"} name={"Signatur_Partner"}/>*/}
														</Table.Cell>
													</Table.Row>
												</Table.Body>
											</Table>
										</Grid.Column>
										<Grid.Column>
											<Table basic="very" compact={"very"}>
												<Table.Body>
													<Table.Row>
														<Table.Cell width={6}><DataField label={"Ort "} fluid value={form["Signatur_Vertreter_Ort"]}
																						 name={"Signatur_Vertreter_Ort"} /></Table.Cell>
														<Table.Cell width={6}><DataField label={"Datum"} fluid value={form["Signatur_Vertreter_Zeit"]}
																						 name={"Signatur_Vertreter_Zeit"} /></Table.Cell>

													</Table.Row>

													<Table.Row>
														<Table.Cell colSpan={2} width={16}><DataField label={"Name"} fluid value={form["Signatur_Vertreter_Name"]}
																									  name={"Signatur_Vertreter_Name"} /></Table.Cell>
													</Table.Row>

													<Table.Row>
														<Table.Cell colSpan={2}>
															<div className="relative">
																<div className={"f6"}>Unterschrift Josef Kränzle GmbH & Co. KG</div>
																<div className="pointer-events-none absolute left-1 f3 pt2">i.A.</div>
																<Image className={"mw-100"} src={signatures[1]} />
															</div>
														</Table.Cell>
													</Table.Row>
												</Table.Body>
											</Table>
										</Grid.Column>
									</Grid.Row>
								</Grid>


							</div>
						</div>

					</Container>


				</Form>

			</div>
		);
	}
}

const DataField = ( {value} ) => <div className={"f6"}>{value}</div>